<template> 
	<v-card dense>
		<s-toolbar label="Para seleccionar una opcion, Pulse el boton:" dark color="#8e8f91"></s-toolbar>
		<v-container>
			<v-row>
				<v-col cols="12" md="4">
					<s-select-definition
						:def="1181"
						label="Destino"
						@input="list($event)"								
						v-model="TypeDestiny"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="4" v-for="col in items" :key="col.DedID">
					<v-btn
						color="warning"
						@click="click(col)" 
						block
						>
						<span class="white--text ">
							{{ col.ChaDescription }}
						</span>
						-
						<span>
							{{ col.TypeFreshProductionDestinationText }}
						</span>
					</v-btn>
				</v-col>
			</v-row>
		</v-container> 
	</v-card> 
</template>

<script>

    import _sPrfChamberService from "@/services/FreshProduction/PrfChamberService";

	export default {
		components: {},

		data() {
			return {
				radios: null,
				items: [],
				TypeDestiny: 0,
			};
		},
		methods: {
			change(val) {
				this.items = val;
			},
			 
			click(val) {
				this.$emit("click", val);
			},
			list(TypeDestiny){
				console.log("TypeDestiny",TypeDestiny);
				_sPrfChamberService.list({TypeDestiny},this.$fun.getUserID())
                .then(r => {
                    console.log(r.data);
                   this.items = r.data;
                })
			}
		},
	};
</script>
